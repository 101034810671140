@font-face {
  font-family: Imported;
  src: url(/src/fonts/Poppins-Regular.ttf);
}
#root{
  font-family: Imported;
}

* {
  box-sizing: border-box;
}


.box-shadow {
  color: #fff;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 7px;
  border-color: #004c99;
  background-color: rgba(0, 58, 117, 0.3);
  box-shadow:0px 3px 6px #004c99,inset 0px 2px 8px #141a1f;
}
.marquee {
  height: 30px;	
  overflow: hidden;
  position: relative;
  background: #1a1a1a;
 }
 .marquee div {
  font-size: 11px;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%);	
  transform:translateX(100%);
  /* Apply animation to this element */	
  -moz-animation: example1 15s linear infinite;
  -webkit-animation: example1 15s linear infinite;
  animation: example1 15s linear infinite;
 }
 /* Move it (define the animation) */
 @-moz-keyframes example1 {
  0%   { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
 }
 @-webkit-keyframes example1 {
  0%   { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
 }
 @keyframes example1 {
  0%   { 
  -moz-transform: translateX(100%); /* Firefox bug fix */
  -webkit-transform: translateX(100%); /* Firefox bug fix */
  transform: translateX(100%); 		
  }
  100% { 
  -moz-transform: translateX(-100%); /* Firefox bug fix */
  -webkit-transform: translateX(-100%); /* Firefox bug fix */
  transform: translateX(-100%); 
  }
 }



